const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/technology"],
    exact: true,
    component: "Technology",
  },
  {
    path: ["/", "/service"],
    exact: true,
    component: "Service",
  },
  {
    path: ["/", "/consultancy"],
    exact: true,
    component: "Consultancy",
  },
  {
    path: ["/", "/industry"],
    exact: true,
    component: "Industry",
  },
];

export default routes;
