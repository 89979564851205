import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

// interface SocialLinkProps {
//   href: string;
//   src: string;
// }

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  // const SocialLink = ({ href, src }: SocialLinkProps) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //     >
  //       <SvgIcon src={src} width="25px" height="25px" />
  //     </a>
  //   );
  // };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("CONTACT")}</Language>
              <Para>
                {t('HOTLINE') + ': +84-934438338'}
              </Para>
              <Para>
                {t('EMAIL') + ': gomgopvietnam@gmail.com'}
              </Para>
              <a href="mailto:gomgopvietnam@gmail.com">
                <Chat>{t('CONNECT')}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("POLICY")}</Title>
              <Large to="/" left="true">
                {t("PRIVACY_POLICY")}
              </Large>
              <Large left="true" to="/">
                {t("TERM_CONDITIONS")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("LANGUAGE")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("vi")}>
                  <SvgIcon
                    src="vietnam.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("ADDRESS")}</Language>
              <Para>CÔNG TY CỔ PHẦN TÍCH LUỸ VIỆT NAM</Para>
              <Para>S2.17, KĐT Vinhomes Ocean Park</Para>
              <Para>Gia Lâm, Hà Nội</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("CATALOG")}</Title>
              <Large left="true" to="/technology">
                {t("TECHNOLOGY_SOLUTIONS")}
              </Large>
              <Large left="true" to="/service">
                {t("SERVICES")}
              </Large>
              <Large left="true" to="/consultancy">
                {t("CONSULTANCY")}
              </Large>
              <Large left="true" to="/industry">
                {t("INDUSTRY_SOLUTIONS")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            {/* <FooterContainer>
              <SocialLink
                href="https://github.com/Adrinlol/create-react-app-adrinlol"
                src="github.svg"
              />
              <SocialLink
                href="https://twitter.com/Adrinlolx"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/in/lasha-kakabadze/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://medium.com/@lashakakabadze/"
                src="medium.svg"
              />
            </FooterContainer> */}
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
